const portBox = document.querySelector(".port-box");
const openPortBtn = document.querySelector(".open-port-btn");
const topPort = document.querySelector(".top-port");
const left = document.querySelector(".left");
const closeLeft = document.querySelector(".close-left");
const closeRight = document.querySelector(".close-right");
const hideMenu = document.querySelector(".hide-menu");
let menu = document.querySelector(".menu");
let menuBox = document.querySelector(".menu-box");
const cvLogo = document.querySelector(".cv-logo");
const cvPage = document.querySelector(".cv-page");
const closeCV = document.querySelector(".close-cv");
const openSkillsBtn = document.querySelector(".open-skills-btn");
const skillsContainer = document.querySelector(".skills-container");
const topSkills = document.querySelector(".top-skills");
const about = document.querySelector(".about");
const aboutPage = document.querySelector(".about-page");
const closeBtn = document.querySelector(".close-btn");
const input = document.querySelector(".theme-switcher input");

input.addEventListener("change", (e) => {
  if (e.target.checked) {
    document.body.setAttribute("data-theme", "dark");
  } else {
    document.body.setAttribute("data-theme", "light");
  }
});

menuBox.addEventListener("click", () => {
  // hideMenu.classList.remove('close-menu');
  menu.classList.toggle("open-menu");
});
hideMenu.addEventListener("click", () => {
  menu.classList.remove("open-menu");
  menu.classList.add("close-menu");
});

openPortBtn.addEventListener("click", () => {
  skillsContainer.classList.add("hide-skills");
  portBox.classList.toggle("hide-port");
});
openSkillsBtn.addEventListener("click", () => {
  portBox.classList.add("hide-port");
  skillsContainer.classList.toggle("hide-skills");
});
topPort.addEventListener("click", () => {
  skillsContainer.classList.add("hide-skills");
  portBox.classList.toggle("hide-port");
});
topSkills.addEventListener("click", () => {
  portBox.classList.add("hide-port");
  skillsContainer.classList.toggle("hide-skills");
});

closeLeft.addEventListener("click", () => {
  portBox.classList.add("hide-port");
});
closeRight.addEventListener("click", () => {
  skillsContainer.classList.add("hide-skills");
});
cvLogo.addEventListener("click", () => {
  cvPage.style.transform = "translateY(0)";
});
closeCV.addEventListener("click", () => {
  cvPage.style.transform = "translateY(-150%)";
});
about.addEventListener("click", () => {
  portBox.classList.add("hide-port");
  skillsContainer.classList.add("hide-skills");
  aboutPage.classList.toggle("hide-about");
});
closeBtn.addEventListener("click", () => {
  aboutPage.classList.add("hide-about");
});
